<template>
    <div class="modal-basic">
        <div class="head">서명하기</div>
        <div class="body">
            <div class="canvas-wrapper">
                <canvas id="canvas" ref="canvas" width="300" height="250" v-text="`이 사각형 안에 사인을 그려주세요`" />
                <div v-if="!start" class="desc">이 사각형 안에 서명을 그려주세요</div>
            </div>
        </div>
        <div class="buttons-basic">
            <div class="flex-row">
                <div
                    @click="onClickButton(btn)"
                    :key="idx"
                    v-for="(btn, idx) in buttons"
                    class="btn-modal"
                    :class="btn.class"
                >
                    {{ btn.label | translate }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalSignPad',
    data: () => ({
        painting: false,
        ctx: null,
        canvas: null,
        start: false,
    }),
    mounted() {
        const canvas = this.$refs.canvas
        this.canvas = canvas
        const ctx = canvas.getContext('2d')
        ctx.strokeStyle = 'black'
        ctx.fillStyle = ctx.strokeStyle
        ctx.lineWidth = 2
        this.ctx = ctx

        canvas.addEventListener(
            'touchstart',
            e => {
                e.preventDefault()
                this.ctx.beginPath()
                this.painting = true
                this.start = true
            },
            false
        )
        canvas.addEventListener(
            'touchmove',
            async e => {
                e.preventDefault()

                const bcr = e.target.getBoundingClientRect()
                const x = e.targetTouches[0].clientX - bcr.x
                const y = e.targetTouches[0].clientY - bcr.y

                if (!this.painting) {
                    this.ctx.beginPath() // path를 생성하고
                    this.ctx.moveTo(x, y) // 해당 좌표로 이동합니다.
                } else {
                    this.ctx.lineTo(x - 5, y - 5)
                    this.ctx.stroke()
                }
            },
            false
        )
        canvas.addEventListener(
            'touchend',
            e => {
                e.preventDefault()
                this.ctx.closePath()
                this.painting = false
            },
            false
        )
    },
    computed: {
        buttons() {
            return [
                {
                    id: 0,
                    label: 'CANCEL',
                    class: 'btn-default',
                },
                {
                    id: 1,
                    label: '입력하기',
                    class: 'btn-primary',
                },
            ]
        },
    },
    methods: {
        onClickButton(btn) {
            if (!btn.id) {
                this.$emit('close')
                return
            }
            try {
                let dataUrl
                let imgBlob
                this.canvas.toBlob(
                    blob => {
                        dataUrl = URL.createObjectURL(blob)
                        imgBlob = blob
                        this.$emit('close', { dataUrl, imgBlob })
                    },
                    'image/jpg',
                    0.5
                )
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.canvas-wrapper {
    position: relative;

    canvas {
        border: 1px solid $grey-02;
        background: $grey-01;
        color: #94999e;
    }
    .desc {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: $grey-06;
    }
}
</style>
